
export default function Articles() {

    return (
        [
            {
                title: 'Summom Bonum',
                body: `Is the wisest thing I can do really only to act according to my personal primary conception of the highest good? It seems perfectly clear I'm leagues detached from the sort of intellectual entelechy, spiritual entelechy that could warrant my conscious imprint on the moral landscape. Perhaps though it's not really like I've much for an alternative option — this analysis in itself is as much of what I'd call ungoverned as anything else I'm keen to undertake, and here I sit, essentially striving for the highest good.<br /><br />Humility, then, seems to constitute the optimal occupation of mind — for what atrocities I may unconsciously bring to pass lurk always behind the nape of perception. I will not apologize in advance, because then I intimate them. But I'll strive to remain humble — aware in this vast night my foot is nailed to the floor through the accelerator, and my highest beams penetrate only so far.`
            },
            {
                title: 'Toast to the Void',
                body: 'Chased from the east by Hell\'s encroaching borders, and from the west by water steadily mounting higher. The only thing funny is this bag of tricks might keep its temperature provided it\'s assailed from both sides simultaneously. Unless one of the frozen poles makes due as its master\'s fancied bid to escape — then burnt, wet, and frostbitten fingers will tug its twine drawstring like a third-wheel fumbling for a first aid kit amid the messiest love triangle anyone ever had the displeasure to try and rectify too long after it\'s gone sour. Maybe the hope\'s misplaced, but the elements do seem to have something like a sense of humor after they get their fill of despair.<br /><br />Let the contents of the trick-bag run amok, no point making a show of its preservation at this point — the dang thang\'s either gonna go down or it ain\'t. Besides, it\'s hardly clear what would be better; to kick the bucket full-frontal like a weak dam with the Mississippi river heaving and swelling at its ass betting on it to crack (or likewise to do it in a punctured hazmat suit skulking through Satan\'s sulfur fields), make like the wind until exhaustion, self-loathing, or both get the upper hand — or to flip the coin for "gotcha" rights with a cheeky game of Russian roulette stacked times six in favor of the dealer and just hope the lights actually go out for real. Can\'t know if the forces of nature are kids it can be kicked with until their kicks are tried on for size — just don\'t be surprised if "a little banged up" is less by a lot than a full dose of the future\'s contents if getting down like that smells appealing enough to attempt.'
            },
            {
                title: 'An Ode to Mystery',
                body: 'The thought or notion that I\'ve known something all along, but seem momentarily to have mislaid it in the recesses of mind. Something that would snap the whole picture together... Do I chase it, or do I let it go? Or is this sensation a thing unto itself, not dependent on the actual retrieval or loss of an answer? To view the state itself as that worthy of exploration — it\'s like there\'s something behind my shoulder telling me the answer in a mute voice, and too shy to let me read its lips.<br /><br />What am I missing? I constantly feel like there\'s something out of place, or tucked in some cabinet when it\'s meant to be out front on the table. What the hell am I looking for? This absolute certainty that it\'s <em>something,</em> is maddening. I don\'t even have the corresponding question at my disposal, and yet the answer\'s absence jabs at my belly like I\'ve left the stove on; if only it were so simple. The fact that I\'m the only one who\'s meant to know what I search for is ridiculous.<br /><br />There\'s something running through the rafters, chuckling at me from behind blind corners. I feel like if I sit patiently it\'ll come and face me, like its game can only go on for as long as I\'m rapt by this baseless confusion. But it knows I want it to come out, and that\'s just enough to keep it hidden. Reneging on my attempts at quietude redoubles its joy manyfold, and its silent laughter is infectious. Now I\'m laughing too — the whole charade is timelessly absurd; an elven ode to impenetrable mystery in which I\'ve played the fool. They thank me for my participation, and warn me not to hurt myself, calculating so furiously without aim like this.'
            },
            {
                title: 'Skyrim',
                body: 'Sometimes you just gotta play Skyrim. There\'s not a whole lot else to it. Evening is falling, the day has been a success, I\'ve recorded several chapters of the audiobook, and I\'ve a few hours of quiet before the sweet embrace of sleep cradles me into tomorrow\'s sunrise.<br /><br />It\'s incredible how meditative this Elder Scrolls game from 2011 still proves to be all these years later. Wandering through the province\'s snowy holds, gazing into the northern lights with Jeremy Soule\'s orchestra serenading the depths of my soul as I navigate toward a distant waypoint. There\'s a sixth installation to the series arriving eventually, and whenever that happens I\'m sure my own, and many other\'s lives will be halted with an abruptness those unfamiliar with Bethesda\'s enchanting masterworks will find positively uncanny. Weeks and months and years are liable thereafter to pass with countless lives enriched by the fantastic depths of its gameplay — a new generation minted who\'d shun allegiance to even the most engaging multiplayer title in favor of solitary adventure through Tamriel\'s boundless frontiers.'
            },
            {
                title: 'Pride and Promotion',
                body: 'The time has more or less arrived where I\'m finding myself flush against the imperative of marketing, promoting, sharing, discussing, and otherwise propogating the knowledge that Dancing Through the Labyrinth exists and is worth people\'s attention. It\'s my suspicion that authors torture themselves with the task of begging and pleading for representation from professional agents not because they thirst for some veneer of prestige or because they\'re actually stuck on the notion that anything other than traditional publishing is somehow fundamentally illegitimate — but because agents serve to absorb the shame and fretfulness associated with pushing one\'s work to audiences independently. The average disposition of the authors I\'ve encountered seems to be diametrically opposed to what they perceive as the mortal imperilment of doing anything other than the writing itself to the end of having others read theirs.<br /><br />This is understandable enough. Long hours engaged in solitudinous contemplation to create what will ostensibly transmit the nuance of one\'s conscience in the highest conceivable resolution with the same social risk as dropping a signed note from an airplane lends itself overwhelmingly to execution by those unenchanted with the necessary evils of brightly-colored, short-form appeals for engagement. That being said — I\'m forced in the final analysis to conclude that the lack of faith inherent to one who\'d shun the prospect of putting themselves out there on behalf of their writing has simply yet to bleed the necessary minimum before choicelessly regarding it with a degree of reverence indistinguishable from that for a loved one.<br /><br />If unremitting exposure was the sole hope to keep your loved ones alive and thriving — would you remain so proud?'
            },
            {
                title: 'Checks and Balances',
                body: 'Bragging is a thing others are supposed to do about you. There is a fine line between that affecting mechanism with which one seeks to explicitly impress the splendor of some feat, and the absolute certainty of a declaration made without concern for anything divorced from its intrinsic function. The latter resides in the domain of what\'s conscionably undertaken; the former, no matter how it struggles to pry itself from between one\'s lips — is only rightfully manifest at the behest of one to whom it does not pertain.<br /><br />Imagine a fish that did not just swim, but all the while called attention to the marvel of its gills, the organic ingenuity of its swim bladder, and the elegance of its course through and about the water\'s currents. Even the most composed among us wouldn\'t be surprised to find themselves projecting the image of it helplessly writhing in the open air against the canvas of their consciousness. This urge betrays no evil, but is an acknowledgment of what great invisible threads bind and balance the contents of the living world — that any of its finite tendrils keeps from saturating the environment to the point of putridity. It is not that it\'s wise to wield the threat of this calibrating tendency, but actually that for those who\'re aware of the power it contains — allowing the matter of virulent boasting to proliferate unchecked is a sad disservice to one\'s fellow beings.'
            },
            {
                title: 'Another Afternoon',
                body: 'I think embracing boredom is really important. I\'m sat here in my buddy\'s cabin, just finished putting this website together, and all I have left to do is craft some more blog posts so the dice don\'t roll less than a respectable handful of times before you get a repeat. It\'s times like this — between the people, and the formatting, and the code, and everything else that isn\'t writing, when it\'s my time to sit down and do the thing I know I\'m meant to, comes the unbridled weight of boredom crashing down on my shoulders like the heavy palms of some unwelcome relative who\'s slithered up behind me.<br /><br />The first instinct is obviously evasion, into social media and food and aimless anxiety, but I\'m reminded of what forced my pen (hand and self in tow) across what first few pages I autonomously wrote: a staunch, spiteful refusal to let the bothersome thick between preoccupations of circumstance molest the time I\'d rightfully annexed as my own — and as assuredly as the scrawling in those silent rooms swept me away then, does the tapping alongside this fireplace, on the cusp of winter in northwestern Pennsylvania, just now. For as much as I feel this is what I\'m meant to do, it\'s astonishing how often I\'m deceived by what can only be described as ambient worry into reflexively groping after the Muse, who\'s guided me unreservedly whenever I sought to distill my world to the shapely confines of the written word. Sometimes I think her too patient with me — but then I\'ve been a supplicant before her since first we met.<br /><br />I\'m not at all angry she takes no physical form beyond the itching in my fingers. More convenient that way, really.'
            },
            {
                title: 'You Rolled a One! Or Rather, THE One',
                body: 'If you haven\'t figured it out yet, this is my blog. The reason it\'s designed so... what most might insist abhorrently — without any dedicated features to share or track the various entries, is twofold.<br><br>...Maybe threefold.<br><br>The second reason is because the year is 2023 and I refuse to believe it\'s the case that people actually read blogs — much less those of the meandering, philosophical variety. The first reason is because it\'s MINE, and I do what I please. The third reason is because for all the petty "merits" a blog offers an author, I\'ve been made to understand the virtue of a custom that does not so easily (as the others) crumple beneath the gravity of the fact I\'m piloting a pretentious sort of public journal.<br><br>That\'s that before expecting anyone to invest their time and money into my book, it\'s good manners to render myself bare in little snippets like this; these dice are my faithful attempt to prove I am sufficiently literate to string words together in a way that isn\'t viscerally offensive to the good, scrupulous grammarians of the English-speaking internet. That, however, is absolutely extraneous to the matter of the blog\'s organization, and alas I find the sole option of an unguided dive into all of what I might feel like presenting here, immeasurably favorable. With no temporal or thematic framework to bind my contributions into any sort of linear progression, it\'s impossible for me to exercise less than complete diligence as I paint the many sides of these dice. The early days and the later days shall all be judged as one.<br><br>Do take care!'
            }
        ]
    );
}